import { createWebHistory, createRouter } from "vue-router";
import Dev from "@/views/Dev.vue";
import About from "@/views/About.vue";
import User from "@/views/User.vue";
import Shop from "@/views/Shop.vue";
import NotFound from "@/views/NotFound.vue";
import Public from "@/views/Public.vue";
//import { store } from "@/store.js";

const routes = [{
    path: "/",
    name: "Home",
    component: Public,
},
{
    path: "/dev",
    name: "Dev",
    component: Dev,
},
{
    path: "/about",
    name: "About",
    component: About,
},
{
    path: "/user/:name",
    name: "User",
    component: User,
    props: true,
},
{
    path: "/shop",
    name: "Shop",
    component: Shop
},
{
    path: "/:catchAll(.*)",
    component: NotFound,
}
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// router.beforeEach((to, from, next) => {
//     if (to.name != 'Home') {
//         next({ name: 'Home' });
//     } else {
//         next();
//     }
// });

//router.beforeEach((to, from, next) => {
// if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
// else next()

// if (to.name !== 'Auth' && typeof store.user.username === 'undefined') {
// 	next({ name: 'Auth' });
// }
//})

export default router;