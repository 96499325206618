<script>

import ShopItem from "@/components/ShopItem.vue";

export default {	
	data(){
		return{
			greeting: "Welcome to the Shoppe!",
			quantityToAdd: 6,
			cartQuantity: 0,
			storeItems: [{name: "Car"}, {name: "Truck"}],
			cartItems: {}
		}
	},
	components:{
		'shop-item': ShopItem
	},
	methods:{		
		addToCartFromChild(itemToAdd){
			this.cartItems[itemToAdd.itemName] = (typeof this.cartItems[itemToAdd.itemName] === 'undefined' ? 0 : this.cartItems[itemToAdd.itemName]) + itemToAdd.quantityToAdd;		
			console.log(this.cartItems);
		}
	}
}
</script>
<template>
	<h1>Shop</h1>
	<div>{{ greeting }}</div>
	<shop-item v-for="item in storeItems" v-bind:itemName="item.name" :key="item.name" @addToCart=addToCartFromChild></shop-item>
	<br />
	<hr />
	<h1>This is in your cart</h1>
	<div v-for="(quantity, itemName) in cartItems" :key="itemName">
		{{itemName}}: {{quantity}}
	</div>
</template>


