<script>
// import { store } from "@/store.js";

export default {
    data() {
        return {
            userIsAuthenticated: false,
            userEmail: ""

        }
    },
}
</script>
<template>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/shop">Shop</router-link>
  </div> -->
    <!-- <div id="nav">
        <div id="links">
            <router-link :to="{ name: 'Home' }">Home</router-link> |
            <router-link :to="{ name: 'About' }">About</router-link> |
            <router-link :to="{ name: 'Shop' }">Shop</router-link> |
            <router-link :to="{ name: 'Inventory' }">Inventory</router-link> |
            <router-link :to="{ name: 'Farm' }">Farm</router-link> |
            <router-link :to="{ name: 'Auth' }">Auth</router-link> |
            <router-link :to="{ name: 'Profile' }">Profile</router-link>
        </div>
        <div id="profile">
            {{ userEmail }} <router-link v-if="userIsAuthenticated" :to="{ name: 'Profile' }">Profile</router-link>
            <router-link v-else :to="{ name: 'Auth' }">Auth</router-link>
        </div>
    </div> -->
    <div id="body">
        <router-view />
    </div>
</template>
<style lang="scss" scoped>
#nav {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
}
</style>