<script>

const context = new AudioContext();

// Signal dampening amount
let dampening = 0.99;
const delayMs = 800;


const song_twinkle = [
    { note: [null, 3, null, null, null, null], chord: "chordC", },
    { note: [null, 3, null, null, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "", },
    { note: [null, null, null, 2, null, null], chord: "chordF", },
    { note: [null, null, null, 2, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "chordC", },
    { note: [null, null, null, null, null, null], chord: "", },
    { note: [null, null, 3, null, null, null], chord: "chordF", },
    { note: [null, null, 3, null, null, null], chord: "", },
    { note: [null, null, 2, null, null, null], chord: "chordC", },
    { note: [null, null, 2, null, null, null], chord: "", },
    { note: [null, null, 0, null, null, null], chord: "chordG", },
    { note: [null, null, 0, null, null, null], chord: "", },
    { note: [null, 3, null, null, null, null], chord: "chordC", },
    { note: [null, null, null, null, null, null], chord: "", },
    { note: [null, 3, null, null, null, null], chord: "chordC", },
    { note: [null, 3, null, null, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "", },
    { note: [null, null, null, 2, null, null], chord: "chordF", },
    { note: [null, null, null, 2, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "chordC", },
    { note: [null, null, null, null, null, null], chord: "", },
    { note: [null, null, 3, null, null, null], chord: "chordF", },
    { note: [null, null, 3, null, null, null], chord: "", },
    { note: [null, null, 2, null, null, null], chord: "chordC", },
    { note: [null, null, 2, null, null, null], chord: "", },
    { note: [null, null, 0, null, null, null], chord: "chordG", },
    { note: [null, null, 0, null, null, null], chord: "", },
    { note: [null, 3, null, null, null, null], chord: "chordC", },
    { note: [null, null, null, null, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "", },
    { note: [null, null, 3, null, null, null], chord: "chordF", },
    { note: [null, null, 3, null, null, null], chord: "", },
    { note: [null, null, 2, null, null, null], chord: "ChordC", },
    { note: [null, null, 2, null, null, null], chord: "", },
    { note: [null, null, 0, null, null, null], chord: "chordG", },
    { note: [null, null, null, null, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "chordC", },
    { note: [null, null, null, 0, null, null], chord: "", },
    { note: [null, null, 3, null, null, null], chord: "chordF", },
    { note: [null, null, 3, null, null, null], chord: "", },
    { note: [null, null, 2, null, null, null], chord: "chordC", },
    { note: [null, null, 2, null, null, null], chord: "", },
    { note: [null, null, 0, null, null, null], chord: "chordG", },
    { note: [null, null, null, null, null, null], chord: "", },
    { note: [null, 3, null, null, null, null], chord: "chordC", },
    { note: [null, 3, null, null, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "", },
    { note: [null, null, null, 2, null, null], chord: "chordF", },
    { note: [null, null, null, 2, null, null], chord: "", },
    { note: [null, null, null, 0, null, null], chord: "chordC", },
    { note: [null, null, null, null, null, null], chord: "", },
    { note: [null, null, 3, null, null, null], chord: "chordF", },
    { note: [null, null, 3, null, null, null], chord: "", },
    { note: [null, null, 2, null, null, null], chord: "chordC", },
    { note: [null, null, 2, null, null, null], chord: "", },
    { note: [null, null, 0, null, null, null], chord: "chordG", },
    { note: [null, null, 0, null, null, null], chord: "", },
    { note: [null, 3, null, null, null, null], chord: "chordC", },
];


export default {
    mounted() {
        console.log("mounted");
        this.setupPlayer();
    },
    data() {
        return {
            row0: {
                chordC: false,
                chordDm: false,
                chordF: false,
                chordG: false,
            },
            row1: {
                chordC: false,
                chordDm: false,
                chordF: false,
                chordG: false,
            },
            row2: {
                chordC: false,
                chordDm: false,
                chordF: false,
                chordG: false,
            },
            row3: {
                chordC: false,
                chordDm: false,
                chordF: false,
                chordG: false,
            }
        }
    },
    methods: {
        setupPlayer() {
            // 2. This code loads the IFrame Player API code asynchronously.
            console.log("Setup");
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        },
        // Returns a AudioNode object that will produce a plucking sound
        pluck(frequency) {
            // We create a script processor that will enable
            // low-level signal sample access
            const pluck = context.createScriptProcessor(4096, 0, 1);

            // N is the period of our signal in samples
            const N = Math.round(context.sampleRate / frequency);

            // y is the signal presently
            const y = new Float32Array(N);
            for (let i = 0; i < N; i++) {
                // We fill this with gaussian noise between [-1, 1]
                y[i] = Math.random() * 2 - 1;
            }

            // This callback produces the sound signal
            let n = 0;
            pluck.onaudioprocess = function (e) {
                // We get a reference to the outputBuffer
                const output = e.outputBuffer.getChannelData(0);

                // We fill the outputBuffer with our generated signal
                for (let i = 0; i < e.outputBuffer.length; i++) {
                    // This averages the current sample with the next one
                    // Effectively, this is a lowpass filter with a
                    // frequency exactly half of sampling rate
                    y[n] = (y[n] + y[(n + 1) % N]) / 2;

                    // Put the actual sample into the buffer
                    output[i] = y[n];

                    // Hasten the signal decay by applying dampening.
                    y[n] *= dampening;

                    // Counting constiables to help us read our current
                    // signal y
                    n++;
                    if (n >= N) n = 0;
                }
            };

            // The resulting signal is not as clean as it should be.
            // In lower frequencies, aliasing is producing sharp sounding
            // noise, making the signal sound like a harpsichord. We
            // apply a bandpass centred on our target frequency to remove
            // these unwanted noise.
            const bandpass = context.createBiquadFilter();
            bandpass.type = "bandpass";
            bandpass.frequency.value = frequency;
            bandpass.Q.value = 1;

            // We connect the ScriptProcessorNode to the BiquadFilterNode
            pluck.connect(bandpass);

            // Our signal would have died down by 2s, so we automatically
            // disconnect eventually to prevent leaking memory.
            setTimeout(() => {
                pluck.disconnect();
            }, 2000);
            setTimeout(() => {
                bandpass.disconnect();
            }, 2000);

            // The bandpass is last AudioNode in the chain, so we return
            // it as the "pluck"
            return bandpass;
        },
        strum(fret, stringCount = 6, stagger = 25) {
            // Fret is an array of finger positions
            // e.g. [-1, 3, 5, 5, -1, -1];
            // 0 is an open string
            // >=1 are the finger positions above the neck
            // Reset dampening to the natural state
            dampening = 0.99;

            // Connect our strings to the sink
            const dst = context.destination;
            for (let index = 0; index < stringCount; index++) {
                if (Number.isFinite(fret[index])) {
                    setTimeout(() => {
                        this.pluck(this.getFrequency(index, fret[index])).connect(dst);
                    }, stagger * index);
                }
            }
        },
        getFrequency(string, fret) {
            // Concert A frequency
            const A = 110;

            // These are how far guitar strings are tuned apart from A
            const offsets = [-5, 0, 5, 10, 14, 19];

            return A * Math.pow(2, (fret + offsets[string]) / 12);
        },
        mute() {
            dampening = 0.89;
        },
        playChord(frets) {
            context.resume().then(this.strum(frets));
        },
        async playSong() {
            // const tileHeight = 3;

            song_twinkle.forEach(async (element, i) => {
                await this.delay((i + 1) * delayMs);
                if (element.chord) {
                    Object.keys(this.row0).forEach((key) => { this.row0[key] = false }); // reset the row.
                    this.row0[element.chord] = true;
                }
            });

            song_twinkle.forEach(async (element, i) => {
                await this.delay(((i + 1) * (delayMs)) + delayMs);
                if (element.chord) {
                    Object.keys(this.row1).forEach((key) => { this.row1[key] = false }); // reset the row.
                    this.row1[element.chord] = true;
                }
            });

            song_twinkle.forEach(async (element, i) => {
                await this.delay(((i + 1) * (delayMs)) + 2 * delayMs);
                if (element.chord) {
                    Object.keys(this.row2).forEach((key) => { this.row2[key] = false }); // reset the row.
                    this.row2[element.chord] = true;
                }
            });

            song_twinkle.forEach(async (element, i) => {
                await this.delay(((i + 1) * (delayMs)) + 3 * delayMs);
                this.playChord(element.note);
                if (element.chord) {
                    Object.keys(this.row3).forEach((key) => { this.row3[key] = false }); // reset the row.
                    this.row3[element.chord] = true;
                }
            });

            //Reset everything
            this.resetAll();
            console.log("Done processing song.");
        },
        delay(milliseconds) {
            return new Promise(resolve => {
                setTimeout(resolve, milliseconds);
                console.log("A");
            });
        },
        async resetAll() {
            await this.delay((song_twinkle.length + 5) * delayMs);
            console.log("Reset");
            Object.keys(this.row3).forEach((key) => { this.row3[key] = false }); // reset the row.
            Object.keys(this.row2).forEach((key) => { this.row2[key] = false }); // reset the row.
            Object.keys(this.row1).forEach((key) => { this.row1[key] = false }); // reset the row.
            Object.keys(this.row0).forEach((key) => { this.row0[key] = false }); // reset the row.
        }
    }
}
</script>
<template>
    <div id="box">
        <div class="controls">
            <div class="button" id="start" @click.prevent="playSong()">Play Song</div>
            <div class="button" id="mute" @click.prevent="mute()">Mute</div>
            <router-link class="button" :to="{ name: 'Home' }">Home</router-link>
        </div>
        <div id="video">
            <!-- <iframe width="660" height="315"
                src="https://www.youtube-nocookie.com/embed/XqZsoesa55w?autoplay=1&start=12" title="Baby Shark!"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> -->

            <div id="player"></div>

            <div id="video-cover"></div>
        </div>
        <div class="columns">
            <div class="tile tile-row-0">
                <div :class="[row0.chordC ? 'tileActive' : '']">
                </div>
                <div :class="[row0.chordDm ? 'tileActive' : '']">
                </div>
                <div :class="[row0.chordF ? 'tileActive' : '']">
                </div>
                <div :class="[row0.chordG ? 'tileActive' : '']">
                </div>
            </div>
            <div class="tile tile-row-1">
                <div :class="[row1.chordC ? 'tileActive' : '']">
                </div>
                <div :class="[row1.chordDm ? 'tileActive' : '']">
                </div>
                <div :class="[row1.chordF ? 'tileActive' : '']">
                </div>
                <div :class="[row1.chordG ? 'tileActive' : '']">
                </div>
            </div>
            <div class="tile tile-row-2">
                <div :class="[row2.chordC ? 'tileActive' : '']">
                </div>
                <div :class="[row2.chordDm ? 'tileActive' : '']">
                </div>
                <div :class="[row2.chordF ? 'tileActive' : '']">
                </div>
                <div :class="[row2.chordG ? 'tileActive' : '']">
                </div>
            </div>
            <div class="notes tile-row-3">
                <div :class="[row3.chordC ? 'activeCord' : '']" @click.prevent="playChord([null, 3, 2, 0, 1, 0]);">C
                    major
                    <br />
                    <img src="star-moon.png" height="140" alt="star">
                </div>

                <!-- <div :class="[row3.chordDm ? 'activeCord' : '']"
                    @click.prevent="if (row3.chordDm) playChord([5, 5, 7, 7, 6, 5]);">
                    Dm
                    <br />
                    <img src="star-moon.png" height="140" alt="star">
                </div> -->

                <div :class="[row3.chordD ? 'activeCord' : '']" @click.prevent="playChord([null, null, 0, 2, 3, 2]);">
                    D Major
                    <br />
                    <img src="star-moon.png" height="140" alt="star">
                </div>
                <!-- <div @click.prevent="playChord([0, 2, 2, 1, 0, 0])">E major</div> -->

                <div :class="[row3.chordEm ? 'activeCord' : '']" @click.prevent="playChord([0, 2, 2, 0, 0, 0])">E Minor
                    <br />
                    <img src="star-moon.png" height="140" alt="star">
                </div>

                <!-- <div :class="[row3.chordF ? 'activeCord' : '']"
                    @click.prevent="if (row3.chordF) playChord([1, 3, 3, 2, 1, 1]);">F major
                    <br />
                    <img src="star-moon.png" height="140" alt="star">
                </div> -->

                <div :class="[row3.chordG ? 'activeCord' : '']" @click.prevent="playChord([1, 2, 0, 0, 3, 3]);">G major
                    <br />
                    <img src="star-moon.png" height="140" alt="star">
                </div>
                <!-- <div @click.prevent="playChord([null, 0, 2, 2, 2, 0])">A major</div> -->
                <!-- <div @click.prevent="playChord([null, 2, 1, 2, 0, 2])">B7</div> -->
            </div>
        </div>

        <!-- <form class="row flex flex-center" @submit.prevent="handleLogin">
        <div class="col-6 form-widget">
            <h1 class="header">Sign In!</h1>
            <p class="description">Sign in via magic link with your email below</p>
            <div>
                <input class="inputField" type="email" placeholder="Your email" v-model="email" />
            </div>
            <div>
                <input type="submit" class="button block" :value="loading ? 'Loading' : 'Send magic link'"
                    :disabled="loading" />
            </div>
        </div>
    </form> -->
    </div>
</template>


<style lang="scss" scoped>
#box {
    background-color: lightsalmon;
    height: 100vh;
    background: url(/anime-stars-background.jpg);
    background-repeat: no-repeat;
    background-size: auto;
}

.notes,
.tile,
.controls {
    display: flex;
    justify-content: center;
}

#video {
    text-align: center;
    position: relative;

    #video-cover {
        width: 100%;
        height: 100%;
        // background-color: green;
        position: absolute;
        top: 0;
    }
}

.notes>div,
.tile>div {
    border: 1px solid black;
    display: block;
    padding: 20px;
    width: 260px;
    text-align: center;
    height: 60px;
    transition: all 0.6s;
}

.tile>div {
    background-color: yellow;
    opacity: 0.1;
}

.notes>div {
    cursor: pointer;
    background-color: #ddddff;
    height: 230px;

    img {
        opacity: 0.3;
        transition: all 1.6s;
    }
}

.activeCord {
    background-color: darkslateblue !important;

    img {
        opacity: 1 !important;
    }
}

.tileActive {
    background-color: yellow !important;
    opacity: 0.7 !important;
}

.controls {
    margin-bottom: 20px;

    #start {
        background-color: lightgreen;
        opacity: 0.5;
    }

    .button {
        width: 120px;
        padding: 6px;
        text-align: center;
        cursor: pointer;
        border: 1px solid black;
    }
}
</style>