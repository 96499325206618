import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import { store } from './store'

import './assets/styles/style.scss';

const app = createApp(App);
// app.use(store); // this causes an error.
app.use(router);
app.config.globalProperties.$filters = {
	str_limit(value, size, show_dot=true) {
		if (!value) return '';
		//value = JSON.stringify(value);		
		if (value.length <= size) {
			return value;
		}
		if (show_dot) {
			return value.substr(0, size) + '...';
		} else {
			return value.substr(0, size);
		}

	}
};
app.mount('#app');